import { atom } from 'jotai';

type ModalState =
  | 'none'
  | 'addExams'
  | 'editExams'
  | 'addPatient'
  | 'editPatient'
  | 'chartSetting'
  | 'forgotPassword'
  | 'deleteUser'
  | 'changePassword'
  | TreatmentModalState;
export type TreatmentModalState =
  | 'startTreatment'
  | 'endTreatment'
  | 'changeTreatment'
  | 'addPastTreatment'
  | 'changePastTreatment';
export const ModalStateAtom = atom<ModalState>('none');
