import { themeColor } from '@common/constant/theme';
import { PatientResponse } from '@common/type/patient';
import styled from 'styled-components';
import PatientsList from './PatientsList';
import { flexStyle, sizeStyle } from '@common/constant/commonStyle';

type Props = {
  patientData: PatientResponse[];
  recentData: PatientResponse[] | undefined;
  patientId: string;
  page: 'main' | 'header';
  selectedIndex: number;
  moveFn: (url: string) => void;
  addFn: () => void;
};

const SearchComponent = ({
  patientData,
  recentData,
  patientId,
  page,
  selectedIndex,
  moveFn,
  addFn,
}: Props) => {
  return (
    <Component
      onMouseDown={e => {
        e.preventDefault();
      }}>
      <Content $page={page}>
        <Hr />
        {/* 입력한 데이터가 없을 때 AddPatient -> 환자 추가 / 입력한 데이터가 있을 때 SamePatient -> 환자 확인 */}
        <>
          {patientData.some(
            num => num.patient_id.toUpperCase() === patientId.toUpperCase(),
          ) ? (
            <>
              {patientData.map(
                (ele, index) =>
                  ele.patient_id.toUpperCase() === patientId.toUpperCase() && (
                    <SamePatient key={index}>
                      <PatientsList
                        data={[ele]}
                        name="same"
                        patientId={patientId}
                        selectedIndex={selectedIndex}
                        moveFn={moveFn}
                      />
                    </SamePatient>
                  ),
              )}
            </>
          ) : (
            <AddPatient $selectedIndex={selectedIndex}>
              <AddIdSpan>No ID search results.</AddIdSpan>
              <AddIdSpan>
                +Add a new patient with ID
                <AddIdBtn
                  data-testid="open-add-patient-modal-button"
                  onClick={() => {
                    addFn();
                  }}>
                  {patientId}
                </AddIdBtn>
              </AddIdSpan>
            </AddPatient>
          )}
        </>
        <SimilarPatient>
          <PatientsList
            data={patientData}
            name="similar"
            patientId={patientId}
            selectedIndex={selectedIndex}
            moveFn={moveFn}
          />
        </SimilarPatient>
        {recentData && (
          <RecentlyPatient>
            <RecentlyTitle>Recently viewed</RecentlyTitle>
            <PatientsList
              data={recentData}
              name="recently"
              patientId={patientId}
              selectedIndex={selectedIndex - patientData.length}
              moveFn={moveFn}
            />
          </RecentlyPatient>
        )}
      </Content>
    </Component>
  );
};

export default SearchComponent;

const Component = styled.div`
  ${sizeStyle('660px')('100%')}
  margin: 0 auto;
`;
const Content = styled.div<{ $page: string }>`
  ${sizeStyle('658px')()}
  background: white;
  border-radius: ${props =>
    props.$page === 'main' ? '0px 0px 25px 25px' : '0px 0px 10px 10px'};
  border: 1px solid ${themeColor.green_200};
  border-top: none;
  padding-bottom: 20px;
  position: absolute;
  z-index: 1;
`;
const Hr = styled.div`
  ${sizeStyle('94%')()}
  border-bottom: 1.5px solid ${themeColor.green_200};
  margin: 0 auto;
`;
const AddPatient = styled.div<{ $selectedIndex: number }>`
  ${sizeStyle('100%')('40px')}
  padding: 10px 62px 10px 50px;
  box-sizing: border-box;
  background: ${props => (props.$selectedIndex === -1 ? '#f5f8fd' : 'white')};
  ${flexStyle()()('space-between')()};
`;
const SamePatient = styled.div`
  color: ${themeColor.green_500};
`;
const AddIdSpan = styled.span`
  color: ${themeColor.green_500};
  font-size: 14px;
  ${flexStyle()('10px')()('center')}
`;
const AddIdBtn = styled.button`
  ${sizeStyle('124px')('20px')}
  border-radius: 9999px;
  border: 1px solid #4fb7d0;
  color: ${themeColor.green_400};
  font-size: 12px;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
const SimilarPatient = styled.div``;
const RecentlyPatient = styled.div``;
const RecentlyTitle = styled.div`
  ${sizeStyle('90%')()}
  margin: 0 auto;
  padding: 14px 0px 14px 30px;
  text-align: start;
  font-size: 14px;
  border-bottom: 1.5px solid ${themeColor.green_200};
  color: ${themeColor.green_500};
`;
